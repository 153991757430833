import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import LinkedInTag from "react-linkedin-insight";
import { Controller, useForm } from "react-hook-form";
import {
  List,
  TextField,
  Autocomplete,
  CircularProgress,
  FormHelperText,
} from "@mui/material";

// Custom Components
import NavListItem from "components/common/NavListItem";
import EmergencySearch from "components/EmergencySearch";
import RegistrationForm from "components/auth/facilityRegister/RegistrationForm";
import NavigationLinks from "components/auth/layout/NavigationLinks";

// Hooks and Context
import { AuthContext } from "context/Auth";
import useSearch from "hooks/useSearch";

// Helpers
import { createFacilityUser } from "./facilityRegister/facilityHelpers";
import { getAddress } from "utils/helpers/getAddress";
import { PeopleRounded, PhoneRounded, PlaceRounded } from "@mui/icons-material";

const FacilityRegisterForm = () => {
  const [loading, setLoading] = useState(false);
  const [firebaseErrors, setFirebaseErrors] = useState("");
  const navigate = useNavigate();

  const address = useSearch("");
  const { user } = useContext(AuthContext);

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
  });

  const watchFacility = watch("facility", "");

  useEffect(() => {
    LinkedInTag.init(1439474);
  }, []);

  const onSubmit = async (formData) => {
    setLoading(true);
    setFirebaseErrors("");

    try {
      await createFacilityUser(formData.email, formData.password, formData);
      LinkedInTag.track(2177722);
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      setFirebaseErrors(error.message);
    }
  };

  const FeaturesList = () => (
    <List dense={true} sx={{ mt: 1 }}>
      <NavListItem
        icon={<PlaceRounded />}
        text="Manage your map listing"
        secondary="Give your visitors the info they need when looking at your facility."
      />
      <NavListItem
        icon={<PeopleRounded />}
        text="Speed up the wait time"
        secondary="Let visitors queue and register virtually before they even get to the facility."
      />
      <NavListItem
        icon={<PhoneRounded />}
        text="Includes onboarding conference call"
        secondary="Make sure everything is set up without a hitch with an onboarding call."
      />
    </List>
  );

  const renderFacilityFields = () => {
    if (watchFacility === "hospital") {
      return (
        <>
          <EmergencySearch control={control} errors={errors} />
          <FeaturesList />
        </>
      );
    }

    if (watchFacility === "urgent-care") {
      return (
        <>
          <Controller
            name="location"
            control={control}
            defaultValue=""
            rules={{ required: "Facility name is required." }}
            render={({ field }) => (
              <TextField
                {...field}
                id="location"
                label="Facility Name"
                type="text"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                fullWidth
                error={!!errors?.location}
                helperText={errors?.location?.message}
                sx={{ pb: 2 }}
              />
            )}
          />

          <Controller
            control={control}
            name="address"
            rules={{
              validate: {
                required: (value) => {
                  if (!value) return "Facility address is required.";
                  if (!getAddress(value))
                    return "We weren't able to get an address from this location. Please choose a different address.";
                  return true;
                },
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="address"
                options={address.suggestions}
                onChange={(event, item) => onChange(item)}
                value={value || null}
                loading={address.loading}
                noOptionsText="No results found"
                getOptionLabel={(option) => option.place_name || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Address"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {address.loading && (
                            <CircularProgress color="primary" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    onChange={(v) => address.onChange(v)}
                    variant="standard"
                    error={!!errors.address}
                  />
                )}
              />
            )}
          />
          {errors?.address && (
            <FormHelperText error={true}>
              {errors.address.message}
            </FormHelperText>
          )}

          <FeaturesList />
        </>
      );
    }

    return null;
  };

  return (
    <>
      <RegistrationForm
        control={control}
        errors={errors}
        loading={loading}
        firebaseErrors={firebaseErrors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        renderFacilityFields={renderFacilityFields}
        watchFacility={watchFacility}
        address={address}
      />
      <NavigationLinks facility />
    </>
  );
};

export default FacilityRegisterForm;
