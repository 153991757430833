import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import firebase from "firebase/compat/app";
import { db } from "services/firebase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Alert,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "hooks/useAuth";
import { getMemoizedZipPrice } from "constants/advertising";
import { useStripe } from "@stripe/react-stripe-js";

const addNewAd = async (uid, location, zip, price) => {
  try {
    const response = await firebase
      .functions()
      .httpsCallable("manageAdSubscription")({
      action: "add",
      uid,
      location,
      zip,
      price,
    });

    if (response.data.success) {
      console.log(
        "Ad successfully added to subscription:",
        response.data.subscriptionId
      );
      return response.data;
    }
    throw new Error("Failed to add advertisement");
  } catch (error) {
    if (error.code === "unauthenticated") {
      throw new Error("User must be logged in");
    } else if (error.code === "internal") {
      throw new Error(`Subscription management failed: ${error.message}`);
    }
    throw error;
  }
};

const AddZipCode = ({
  user,
  userData,
  adSubscription,
  location,
  open,
  close,
  prices,
  zips,
  setZips,
  setZipsAdded,
}) => {
  const stripe = useStripe();
  const { canUseFreeTrial } = useAuth();
  const { handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFreeZipSubmission = async (currentZip) => {
    const adData = {
      location: userData.location,
      zip: currentZip,
    };

    try {
      const result = await firebase.functions().httpsCallable("startFreeTrial")(
        {
          zip: currentZip,
          adData,
        }
      );

      if (result.data.success) {
        setZipsAdded(true);
        close();
      } else {
        throw new Error("Failed to start free trial");
      }
    } catch (err) {
      setError(err.message || "Failed to add free ZIP code. Please try again.");
      console.error("Free ZIP submission error:", err);
    }
  };

  const handlePaidZipSubmission = async (currentZip, price) => {
    console.log(adSubscription);
    console.log(user.uid, userData.location, String(currentZip), price.id);
    if (adSubscription) {
      try {
        const result = await addNewAd(
          user.uid,
          userData.location,
          currentZip,
          price.id
        );
        setZipsAdded(true);
        close();
        return result;
      } catch (err) {
        throw new Error(err.message || "Failed to add advertisement");
      }
    }

    // Create a new checkout session
    try {
      const pendingZip = {
        zip: currentZip,
        status: "pending",
        location: userData.location,
        uid: user.uid,
        price: price.id,
      };

      await db
        .collection("zips")
        .doc(currentZip)
        .collection("ads")
        .doc(user.uid)
        .set(pendingZip);

      const checkout = {
        price: price.id,
        payment_method_collection: "if_required",
        success_url: `${window.location.origin}/dashboard/advertising/success`,
        cancel_url: window.location.href,
        metadata: {
          zip: currentZip,
          adId: user.uid,
        },
      };

      console.log({ checkout });

      const docRef = await db
        .collection("users")
        .doc(user.uid)
        .collection("checkout_sessions")
        .add(checkout);

      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          throw new Error(`An error occurred: ${error.message}`);
        }
        if (sessionId) {
          await stripe.redirectToCheckout({ sessionId });
        }
      });
    } catch (err) {
      throw new Error("Failed to create checkout session");
    }
  };

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      const currentZip = String(open.zip);
      const price = getMemoizedZipPrice(open.population, prices);

      if (!price && canUseFreeTrial) {
        throw new Error("Invalid ZIP code population");
      }

      if (canUseFreeTrial) {
        await handleFreeZipSubmission(currentZip);
        setLoading(false);
      } else {
        await handlePaidZipSubmission(currentZip, price);
        // Only set loading to false if we're not creating a checkout session
        if (adSubscription) {
          setLoading(false);
        }
      }
    } catch (err) {
      setError(err.message);
      console.error("Submission error:", err);
      setLoading(false);
    }
  };

  return (
    <Dialog open={!!open} onClose={close}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add ZIP Code</DialogTitle>

        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            {canUseFreeTrial
              ? "Advertise in this ZIP code to have your facilities be shown as a category to all users in the area."
              : "Purchase this ZIP code to have your facilities be shown as a category to all users in the area."}
          </DialogContentText>

          {canUseFreeTrial || adSubscription ? null : (
            <DialogContentText variant="body2" sx={{ mb: 2 }}>
              You will be taken to Stripe to complete your payment.
            </DialogContentText>
          )}

          {open ? (
            <Box sx={{ textAlign: "right", mt: 2, mb: 0 }}>
              {canUseFreeTrial ? null : (
                <Typography variant="body">
                  <strong>
                    You will be billed $
                    {getMemoizedZipPrice(open.population, prices)?.price}/month.
                  </strong>
                </Typography>
              )}
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            Close
          </Button>
          {!adSubscription ? (
            <LoadingButton
              loading={loading}
              type="submit"
              disabled={loading}
              autoFocus
              variant="contained"
            >
              {canUseFreeTrial
                ? "Claim Your Free ZIP Code"
                : "Proceed to Payment"}
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={loading}
              type="submit"
              disabled={loading}
              autoFocus
              variant="contained"
            >
              Add ZIP Codes
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

AddZipCode.propTypes = {
  user: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  adSubscription: PropTypes.object,
  location: PropTypes.object.isRequired,
  open: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  close: PropTypes.func.isRequired,
  prices: PropTypes.array.isRequired,
  zips: PropTypes.array.isRequired,
  setZips: PropTypes.func.isRequired,
  setZipsAdded: PropTypes.func.isRequired,
};

export default AddZipCode;
