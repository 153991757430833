import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Chip,
  Alert,
  Typography,
} from "@mui/material";
import capitalize from "utils/helpers/capitalize";
import { useAuth } from "hooks/useAuth";

/**
 * Displays a table of ZIP codes where the user currently advertises
 * @param {Object} props
 * @param {Array} props.zips - Current ZIP codes being advertised in
 * @param {Array} props.nearbyZips - Nearby ZIP codes with population data
 * @param {Function} props.getZipPrice - Function to calculate ZIP code price
 * @param {Function} props.onRemoveZip - Handler for removing a ZIP code
 * @param {Function} props.onPurchaseZip - Handler for purchasing an inactive ZIP code
 */
const CurrentZipsTable = ({
  zips,
  nearbyZips,
  getZipPrice,
  onRemoveZip,
  onPurchaseZip,
}) => {
  const { user } = useAuth();

  if (zips.length === 0) {
    return (
      <Alert severity="info" sx={{ mb: 2 }}>
        You don't currently have any ZIP codes you're advertising in.
      </Alert>
    );
  }

  return (
    <>
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        ZIP Codes You Own
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="current zip codes">
          <TableHead>
            <TableRow>
              <TableCell>ZIP Code</TableCell>
              <TableCell>Population</TableCell>
              <TableCell>Population-based Monthly Fee</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {zips.map((zip) => {
              const populationData = nearbyZips?.find(
                (nz) => String(nz.zip) === String(zip.zip)
              );
              return (
                <TableRow key={zip.zip}>
                  <TableCell>{zip.zip}</TableCell>
                  <TableCell>
                    {populationData
                      ? populationData.population.toLocaleString("en-US")
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {zip.isTrial ? (
                      <strong>FREE</strong>
                    ) : populationData ? (
                      `$${getZipPrice(populationData.population)}.00`
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={capitalize(zip.status)}
                      color={zip.status === "active" ? "success" : "default"}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="right">
                    {zip.status === "active" ? (
                      <Button
                        onClick={() => onRemoveZip(zip)}
                        color="error"
                        size="small"
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          onPurchaseZip({
                            zip: zip.zip,
                            population: populationData?.population,
                          })
                        }
                        color="primary"
                        size="small"
                      >
                        Reactivate
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CurrentZipsTable;
