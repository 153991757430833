import React, { useState, useEffect, useRef } from "react";
import firebaseApp, { db } from "services/firebase";
import firebase from "firebase/compat/app";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [subscription, setSubscription] = useState();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const unsubRef = useRef();

  const getData = (uid) => {
    return db
      .collection("users")
      .doc(uid)
      .onSnapshot((user) => {
        setUserData(user.data());
      });
  };

  useEffect(() => {
    let authListener = firebaseApp.auth().onAuthStateChanged(async (user) => {
      setUser(user);

      if (user) {
        await firebaseApp.auth().currentUser?.getIdToken(true);
        const decodedToken = await firebaseApp
          .auth()
          .currentUser?.getIdTokenResult();

        let role = decodedToken?.claims?.stripeRole;
        setSubscription(role);
        unsubRef.current = getData(user.uid);
      } else {
        setSubscription(null);
        setUserData(null);
      }
      setLoading(false);
    });

    return () => {
      authListener();
      if (unsubRef.current) unsubRef.current();
    };
  }, []);

  const handleLogout = async () => {
    if (unsubRef.current) {
      unsubRef.current();
      unsubRef.current = null;
    }
    await firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        subscription,
        userData,
        userLoading: loading,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
