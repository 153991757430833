import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import ZipTableContent from "./ZipTable/ZipTableContent";

const ZipTable = ({
  loading,
  subLoading,
  user,
  location,
  zips,
  setAddOpen,
  switchToBranding,
  prices,
}) => {
  if (loading || subLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ZipTableContent
      location={location}
      zips={zips}
      setAddOpen={setAddOpen}
      switchToBranding={switchToBranding}
      prices={prices}
      user={user}
    />
  );
};

export default ZipTable;
