import firebase from "firebase/compat/app";

/**
 * Calculates the price for a ZIP code based on its population
 * @param {number} pop - Population of the ZIP code
 * @param {Array} prices - Array of price tiers
 * @returns {string} Price for the ZIP code
 */
export const getZipPrice = (pop, prices) => {
  if (pop <= 10000) return prices[0]?.price || "";
  if (pop <= 50000) return prices[1]?.price || "";
  if (pop <= 100000) return prices[2]?.price || "";
  return "";
};

/**
 * Splits an array into chunks of specified size (used for Firebase batch operations)
 * @param {Array} inputArray - Array to split
 * @param {number} chunkSize - Size of each chunk (default: 30)
 * @returns {Array} Array of arrays, each containing at most chunkSize elements
 */
export const splitArrayIntoChunks = (inputArray, chunkSize = 30) => {
  return inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
};

/**
 * Removes an advertisement from a specific ZIP code
 * @param {string} uid - User ID
 * @param {string|number} zip - ZIP code to remove
 * @returns {Promise} Response from the Firebase function
 */
export const removeAd = async (uid, zip) => {
  console.log({ uid, zip });

  try {
    const response = await firebase
      .functions()
      .httpsCallable("manageAdSubscription")({
      action: "remove",
      uid,
      zip,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to remove ad:", error);
    throw error;
  }
};
