import React, { useState } from "react";
import { Box, Alert, CircularProgress, Link } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import { useNearbyZips } from "hooks/useNearbyZips";
import {
  removeAd,
  getZipPrice,
} from "components/dashboard/advertising/ZipTable/zipHelpers";
import CurrentZipsTable from "./CurrentZipsTable";
import AvailableZipsTable from "./AvailableZipsTable";
import ConfirmDialog from "./ConfirmDialog";

/**
 * Main ZIP code management component for advertising
 * Displays current and available ZIP codes for advertising
 * @param {Object} props
 * @param {boolean} props.loading - Main loading state
 * @param {Object} props.location - Location data including ZIP and branding
 * @param {Array} props.zips - Current ZIP codes being advertised in
 * @param {Function} props.setAddOpen - Function to open add ZIP dialog
 * @param {Function} props.switchToBranding - Function to switch to branding view
 * @param {Array} props.prices - Price tiers for different population ranges
 * @param {Object} props.user - Current user object
 */
const ZipTableContent = ({
  location,
  zips,
  setAddOpen,
  switchToBranding,
  prices,
  user,
}) => {
  const { canUseFreeTrial } = useAuth();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [removingZip, setRemovingZip] = useState(null);
  const { nearbyZips, isLoading: nearbyZipsLoading } = useNearbyZips(
    location,
    zips
  );

  const handleRemoveZip = (zip) => {
    setConfirmOpen(true);
    setRemovingZip(zip);
  };

  const handleConfirmRemove = async () => {
    setConfirmed(true);
    try {
      await removeAd(user.uid, removingZip.zip);
      setConfirmOpen(false);
      setConfirmed(false);
      // Assume the parent component will handle updating the zips list
    } catch (error) {
      setConfirmed(false);
    }
  };

  if (
    location &&
    !location?.branding?.logo &&
    !location?.branding?.website &&
    !location?.branding?.tagline
  ) {
    return (
      <Alert severity="error">
        Your advertising will not show until you add your branding. Please{" "}
        <Link
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={switchToBranding}
        >
          add your branding
        </Link>
        .
      </Alert>
    );
  }

  return (
    <Box>
      {nearbyZipsLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {nearbyZips?.length === 0 ? (
            <Alert severity="info" sx={{ mt: 2 }}>
              There are no ZIP codes in your area where you can advertise.
            </Alert>
          ) : (
            <>
              <CurrentZipsTable
                zips={zips}
                nearbyZips={nearbyZips}
                getZipPrice={(price) => getZipPrice(price, prices)}
                onRemoveZip={handleRemoveZip}
                onPurchaseZip={setAddOpen}
              />

              <AvailableZipsTable
                nearbyZips={nearbyZips}
                existingZips={zips.map((z) => z.zip)}
                canUseFreeTrial={canUseFreeTrial}
                getZipPrice={(price) => getZipPrice(price, prices)}
                onAddZip={setAddOpen}
              />
            </>
          )}
        </>
      )}

      <ConfirmDialog
        open={confirmOpen}
        onClose={() => !confirmed && setConfirmOpen(false)}
        onConfirm={handleConfirmRemove}
        loading={confirmed}
        title="Are you sure?"
        content="Removing this ZIP will remove your advertisement from this ZIP code."
        confirmText="Yes, remove this ZIP"
      />
    </Box>
  );
};

export default ZipTableContent;
