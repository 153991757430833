import React, { useContext, useState, useEffect, useRef } from "react";
import Loading from "./Loading";
import EmailConfirmation from "./EmailConfirmation";
import { Navigate } from "react-router";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import firebaseApp, { db } from "services/firebase";
import { AuthContext } from "context/Auth";

import Advertising from "./dashboard/advertising/Advertising";
import Billing from "./dashboard/Billing";
import AdminTimeForm from "./dashboard/AdminTimeForm";
import Scheduler from "./dashboard/Scheduler";
import WaitingRoom from "./queue/WaitingRoom";
import BetaDisclaimer from "./dashboard/BetaDisclaimer";

import TextSequence from "./vaccine/TextSequence";
import VirtualRegistrationSettings from "./vaccine/queue/VirtualRegistrationSettings";

import DashboardInner from "./styled/DashboardInner";
import PatientDashboard from "./chatbot/PatientDashboard";
import PatientBilling from "./chatbot/PatientBilling";
import MuDrawer from "./mui/MuDrawer";
import MuWrapper from "./common/Wrapper";
import { ChatProvider } from "context/Chat";
import { LocationContext } from "context/Location";
import { Alert, Box, CircularProgress } from "@mui/material";
import Account from "./dashboard/Account";
import Upgrade from "./dashboard/Upgrade";
import Approval from "./dashboard/admin/Approval";
import { useAuth } from "hooks/useAuth";

const DashboardContent = ({
  children,
  data,
  dbError,
  firebaseApp,
  loading,
  resendEmail,
  submitted,
  subscription,
  user,
  userData,
}) => {
  if (!user.emailVerified)
    return (
      <MuWrapper>
        <Routes>
          <Route
            path="/*"
            exact
            element={
              <EmailConfirmation
                firebase={firebaseApp}
                loading={loading}
                submitted={submitted}
                resendEmail={resendEmail}
                dbError={dbError}
              />
            }
          />
        </Routes>
      </MuWrapper>
    );

  if (!userData?.role)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
        <CircularProgress color="primary" size={30} />
      </Box>
    );

  if (!subscription && userData?.role && userData?.role !== "facility")
    return (
      <MuWrapper>
        <Billing uid={user.uid} role={userData?.role} user={user} />
      </MuWrapper>
    );

  if (userData?.role === "facility")
    return (
      <>
        {data.pending === true ? (
          <Alert severity="info" sx={{ mt: 4, mb: "-10px" }}>
            Your facility is under manual review and will not appear on the map
            until it has been approved. This usually takes around 1-2 days.
          </Alert>
        ) : null}

        {data.pending === "rejected" ? (
          <Alert severity="error" sx={{ mt: 4, mb: "-10px" }}>
            Your facility has been rejected and will not appear on the map. If
            you believe this decision has been made in error, please contact us
            at <a href="mailto:support@hlthdsk.com">support@hlthdsk.com</a>.
          </Alert>
        ) : null}

        {children}
      </>
    );

  if (subscription === "patient" || subscription === "professional")
    return (
      <Routes>
        <Route
          exact
          path="/"
          element={
            <div>
              <PatientDashboard userData={userData} branding={data.branding} />
            </div>
          }
        />
        <Route
          path="/msi"
          element={
            <div style={{ height: "100dvh" }}>
              <PatientDashboard userData={userData} branding={data.branding} />
            </div>
          }
        />
        <Route
          path="/account"
          element={<PatientBilling userData={userData} />}
        />

        {/* Catch-all route to dashboard */}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    );
};

const Dashboard = () => {
  const boxRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [dbError, setDbError] = useState(false);

  const { user, subscription, userData, userLoading } = useAuth();
  const { location, locationLoaded } = useContext(LocationContext);

  useEffect(() => {
    if (locationLoaded && location) {
      getTimes();
    }
  }, [locationLoaded, location]);

  const getTimes = () => {
    const initialData = location;

    if (initialData.waitTimes) {
      const adminTime = initialData.waitTimes
        .reverse()
        .find((time) => time.dashboard);
      const lab = adminTime?.lab || null;
      const xray = adminTime?.xray || null;
      const ultrasound = adminTime?.ultrasound || null;
      const ct = adminTime?.ct || null;
      const mri = adminTime?.mri || null;
      const telehealth = adminTime?.telehealth || null;
      const customPhone = adminTime?.customPhone || null;
      const dashboard = adminTime?.dashboard || null;
      const newData = {
        ...initialData,
        lab,
        xray,
        ultrasound,
        ct,
        mri,
        telehealth,
        customPhone,
        dashboard,
      };
      setData(newData);
    } else {
      setData(initialData);
    }
  };

  const resendEmail = () => {
    setLoading(true);

    user
      .sendEmailVerification()
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      })
      .catch((error) => {
        setLoading(false);
        setDbError(error.message);
        console.log(error.message);
      });
  };

  if (user === null) {
    return <Navigate to="/login/" />;
  }

  if (!user || userLoading)
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  let only_vaccines = data.type === 3;
  if (
    only_vaccines &&
    window.location.pathname.replaceAll("/", "") === "dashboard"
  ) {
    window.location = "/dashboard/vaccine/queue";
    return;
  }

  return (
    <ChatProvider>
      <MuDrawer boxRef={boxRef}>
        <DashboardContent
          user={user}
          loading={loading}
          subscription={subscription}
          userLoading={userLoading}
          userData={userData}
          data={data}
          submitted={submitted}
          firebaseApp={firebaseApp}
          dbError={dbError}
          resendEmail={resendEmail}
        >
          <Routes>
            <Route exact path="/" element={<AdminTimeForm data={data} />} />

            <Route
              path="/sequence"
              element={
                <div className="inner">
                  <VirtualRegistrationSettings data={data} setData={setData} />
                  <TextSequence data={data} setData={setData} />
                </div>
              }
            />

            <Route
              path="/queue"
              element={<WaitingRoom data={data} setData={setData} />}
            />
            <Route
              path="/schedule"
              element={<Scheduler data={data} setData={setData} db={db} />}
            />

            <Route
              path="/account"
              element={<Account uid={user.uid} subscription={subscription} />}
            />

            <Route
              path="/upgrade"
              element={<Upgrade uid={user.uid} subscription={subscription} />}
            />

            <Route path="/advertising/*" element={<Advertising />} />

            {/* Admin paths */}
            <Route path="/admin/approval" element={<Approval />} />

            {/* Catch-all route to dashboard */}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>

          <BetaDisclaimer />
        </DashboardContent>
      </MuDrawer>
    </ChatProvider>
  );
};

export default Dashboard;
