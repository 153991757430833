import { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "context/Auth";
import firebaseApp from "services/firebase";

/**
 * useAuth is a custom hook that centralizes authentication and user state management.
 * It wraps Firebase authentication and provides additional functionality for:
 * - User authentication state (logged in, verified, role status)
 * - Subscription management
 * - Email verification
 * - Loading and error states
 *
 * The hook serves as a single source of truth for authentication state across the app,
 * abstracting away the complexity of directly managing Firebase auth and user data.
 *
 * @returns {Object} An object containing:
 * - User state (user, userData, subscription)
 * - Authentication states (isAuthenticated, isVerified, hasRole)
 * - Loading and error states (loading, submitted, dbError)
 * - Auth methods (resendEmail, logout)
 *
 * @example
 * const {
 *   user,
 *   isAuthenticated,
 *   hasValidSubscription,
 *   logout
 * } = useAuth();
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dbError, setDbError] = useState(null);

  // Ensure the hook is used within an AuthProvider
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  const { user, subscription, userData, userLoading, logout } = context;

  // Memoize commonly used auth state combinations
  const isAuthenticated = useMemo(() => {
    return user !== null && !userLoading;
  }, [user, userLoading]);

  const isVerified = useMemo(() => {
    return isAuthenticated && user?.emailVerified;
  }, [isAuthenticated, user]);

  const hasRole = useMemo(() => {
    return isVerified && userData?.role;
  }, [isVerified, userData]);

  const hasValidSubscription = useMemo(() => {
    return hasRole && (subscription || userData?.role === "facility");
  }, [hasRole, subscription, userData]);

  const canUseFreeTrial = useMemo(() => {
    return subscription === "facility" && !userData?.hasUsedFreeTrial;
  }, [subscription, userData]);

  // Email verification handler
  const resendEmail = async () => {
    setLoading(true);
    setDbError(null);

    try {
      await user.sendEmailVerification();
      setSubmitted(true);
    } catch (error) {
      setDbError(error.message);
      console.error("Email verification error:", error);
    } finally {
      setLoading(false);
    }
  };

  // Reset error state when user changes
  useEffect(() => {
    if (user) {
      setDbError(null);
      setSubmitted(false);
    }
  }, [user]);

  return {
    // User state
    user,
    userData,
    subscription,
    userLoading,

    // Verification state
    loading,
    submitted,
    dbError,

    // Computed state
    isAuthenticated,
    isVerified,
    hasRole,
    hasValidSubscription,
    canUseFreeTrial,

    // Methods
    resendEmail,
    logout,
  };
};
